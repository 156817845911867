import React from "react"
import Slider from "react-slick";

import slide1 from "../images/affi-expo.png"
import slide2 from "../images/circovac.png"
import slide3 from "../images/future-ed.png"
import slide4 from "../images/speaker-poster.jpg"
import slide5 from "../images/wms-poster.jpg"
import slide6 from "../images/SYNERGY.jpg"

import galleryStyles from "../styles/pages/gallery.module.css"

const ImgSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 10000,
        fade: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: false,
                }
            }
        ]
    }
    return (
        <div className={ galleryStyles.sliderContainer }>
            <Slider {...settings}>
                <div><img src={ slide1 } alt=""/></div>
                <div><img src={ slide2 } alt=""/></div>
                <div><img src={ slide3 } alt=""/></div>
                <div><img src={ slide4 } alt=""/></div>
                <div><img src={ slide5 } alt=""/></div>
                <div><img src={ slide6 } alt=""/></div>
            </Slider>
        </div>
    );
}

export default ImgSlider