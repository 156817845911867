import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImgSlider from "../components/slider"
import Video from "../components/video"

import titleImg from "../images/gallery-title-img.jpg"
import logo1 from "../images/Affinity.png"
import logo2 from "../images/comizio-logo.png"
import logo3 from "../images/future-ed-no-bg.png"
import logo4 from "../images/halsa.png"
import logo5 from "../images/hoopwear-athletics.png"
import logo6 from "../images/imi-farms.png"
import logo7 from "../images/mos.png"
import logo8 from "../images/pacific-breeders.png"
import logo9 from "../images/pullet-up.png"
import logo10 from "../images/suigen.png"
import logo11 from "../images/tribung-manok.png"
import logo12 from "../images/this-space-awaits.png"
import avp from "../videos/truenorth-avp.mp4"
import avpPosterImg from "../images/avp-poster-img.png"
import halsa from "../images/halsa-animation.png"
import purinaPrime from "../images/purina-prime.png"
import arawValues from "../images/araw-values.png"
import agoraAwards from "../images/agora-awards.png"

import "../../node_modules/slick-carousel/slick/slick.css"; 
import "../../node_modules/slick-carousel/slick/slick-theme.css";

import galleryStyles from "../styles/pages/gallery.module.css"

const GalleryPage = () => (
  <Layout location="gallery">
    <SEO title="Gallery" />
    <div className={ galleryStyles.titleSection }> 
      <div className={ galleryStyles.title }>
        <h1>Gallery</h1>
        <h5>Browse through the company's latest works.</h5>
      </div>
      <div className={ galleryStyles.titleImg }>
        <img src={ titleImg } />
      </div>
    </div>
    <div className={ galleryStyles.logoWorks }>
      <div className={ galleryStyles.logos}>
        <div className={ galleryStyles.logoRow }>
          <img src={ logo1 } />
          <img src={ logo2 } />
          <img src={ logo3 } />
          <img src={ logo4 } />
        </div>
        <div className={ galleryStyles.logoRow }>
          <img src={ logo5 } />
          <img src={ logo6 } />
          <img src={ logo7 } />
          <img src={ logo8 } />
        </div>
        <div className={ galleryStyles.logoRow }>
          <img src={ logo9 } />
          <img src={ logo10 } />
          <img src={ logo11 } />
          <img src={ logo12 } />
        </div>
      </div>
      <div className={ galleryStyles.logoWorksTitle}>
        <h1>Logo Works</h1>
      </div>
    </div>
    <div className={ galleryStyles.graphicWorks }>
      <div className={ galleryStyles.graphicWorksTitle }>
        <h1>Graphic Works</h1>
      </div>
      <div className={ galleryStyles.posters }>
        <ImgSlider />
      </div>
    </div>
    <div className={ galleryStyles.videoWorks }>
      <div className={ galleryStyles.videoWorksTitle }>
        <h1>Video Works</h1>
      </div>
      <div className={ galleryStyles.videos }>
        <div className={ galleryStyles.video }>
          <div className={ galleryStyles.avpWrapper }>
            <video
                controls
                poster={ avpPosterImg }
                className={ galleryStyles.avp }
            >
                <source src={ avp } type="video/mp4" />
                Your device does not support playing 'video/mp4' videos.
            </video>
          </div>
          <div className={ galleryStyles.videoCaption }>
            <h3>TrueNorth ArtHouse</h3>
            <p>(Audio Video Presentation)</p>
          </div>
        </div>
        <div className={ galleryStyles.thumbnails }>
          <h3>Others</h3>
          <div className={ galleryStyles.thumbnailRow }>
            <div className={ galleryStyles.thumbnail }>
              <a href="https://www.youtube.com/watch?v=G3lS7XetIPY"><img src={ agoraAwards } alt=""/></a>
              <div className={ galleryStyles.caption }>
                <a href="https://www.youtube.com/watch?v=G3lS7XetIPY">38th Agora Award</a>
                <p>(Video Animation)</p>
              </div>
            </div>
            <div className={ galleryStyles.thumbnail }>
              <a href="https://www.youtube.com/watch?v=K-O3oiqGQnk"><img src={ purinaPrime } alt=""/></a>
              <div className={ galleryStyles.caption }>
                <a href="https://www.youtube.com/watch?v=K-O3oiqGQnk">Purina Prime</a>
                <p>(TV Commercial)</p>
              </div>
            </div>
          </div>
          <div className={ galleryStyles.thumbnailRow }>
            <div className={ galleryStyles.thumbnail }>
              <a href="https://www.youtube.com/watch?v=j6Lz5zPOsAk"><img src={ halsa } alt=""/></a>
              <div className={ galleryStyles.caption }>
                <a href="https://www.youtube.com/watch?v=j6Lz5zPOsAk">Halsa</a>
                <p>(Video Animation)</p>
              </div>
            </div>
            <div className={ galleryStyles.thumbnail }>
              <a href="https://www.youtube.com/watch?v=DrlKdlfqaGE"><img src={ arawValues } alt=""/></a>
              <div className={ galleryStyles.caption }>
                <a href="https://www.youtube.com/watch?v=DrlKdlfqaGE">Araw Values Award</a>
                <p>(TV Commercial)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default GalleryPage